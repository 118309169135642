<template>
    <div class="g-footer">

    </div>
  </template>
  
  <script>
  
  export default {
    name: 'GFooter',
    components: {
    }
  }
  </script>
  
  <style>
  
  </style>
  