<template>
    <div class="g-content">
        <!-- <router-view></router-view> -->
         <evoKids></evoKids>
    </div>
  </template>
  
  <script>
  import evoKids from '@/views/evoKids.vue';
  export default {
    name: 'GContent',
    components: {
      evoKids
    }
  }
  </script>
  
  <style>
  
  </style>
  